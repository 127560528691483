import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useIntl } from 'react-intl';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';

import { calculatingSelectedContractedLives } from '../../../utils';
import messages from './messages';
import { Header, Container, StyledHr } from './styles';

const StripePaymentElementForm: React.FC<{
	fullName?: string;
	context?: {
		location: string;
		variant: string;
		companySize: string;
		selectedCoveredLives: number;
	};
}> = ({ context }) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();

	const onLoadError = (
		event: { elementType: 'payment' | 'address'; error: StripeError },
		elementType: string,
	): void => {
		logEvent(`Teams : Checkout : ${elementType} : Stripe: Load Error`, {
			error: event.error.message ?? '',
			selectedCoveredLives: context?.selectedCoveredLives ?? '',
			selectedContractedLives: calculatingSelectedContractedLives(context?.selectedCoveredLives ?? 0),
			companySize: context?.companySize ?? '',
			location: context?.location ?? '',
			variant: context?.variant ?? '',
		});
	};

	return (
		<Container data-testid="stripe-payment-element-form">
			<Header>{formatMessage(messages.header)}</Header>
			<PaymentElement
				options={{
					wallets: { applePay: 'never', googlePay: 'never' },
					terms: { card: 'never' },
				}}
				onLoadError={event => onLoadError(event, 'Payment')}
			/>
			<StyledHr />
			<Header>{formatMessage(messages.billingHeader)}</Header>
			<AddressElement
				options={{
					mode: 'billing',
					// TOODO: Figure out how to prevent validation from
					// triggering on mount after initial submission (submit, leave, come back)
					// defaultValues: {
					// 	name: fullName,
					// },
				}}
				onLoadError={event => onLoadError(event, 'Address')}
			/>
		</Container>
	);
};

export default StripePaymentElementForm;
